<template>
    <div class="container">
        <el-steps :space="180" :active="2" align-center>
            <el-step title="验证当前手机号"></el-step>
            <el-step title="绑定新手机号"></el-step>
            <el-step title="完成"></el-step>
        </el-steps>
        <el-form :label-position="labelPosition" ref="form2" label-width="340px" :model="ruleForm" :rules="rules">
            <el-form-item label="新手机号" prop="phone">
                <el-input style="width: 300px" v-model="ruleForm.phone" placeholder="请输入新的手机号码"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
                <el-input style="width: 300px" v-model="ruleForm.code" placeholder="请输入4位验证码">
                    <span @click="send" style="cursor: pointer; font-size: 13px" slot="suffix">{{
                        time === 0 ? '发送验证码' : `重新发送${time}s`
                    }}</span>
                </el-input>
            </el-form-item>
        </el-form>
        <div class="btn">
            <el-button type="primary" @click="phoneSend">确认</el-button>
            <el-button type="info" @click="Jump">返回</el-button>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            labelPosition: 'right',
            ruleForm: {
                phone: '',
                code: ''
            },
            time: 0,
            rules: {}
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        send() {
            this.sendCode(this.ruleForm.phone);
        },
        phoneSend() {
            if (!/^1[3-9]\d{9}$/.test(this.ruleForm.phone)) {
                this.$message.error('请输入正确手机号');
                return;
            }
            this.$http
                .get('/sms/verify', {
                    phone: this.ruleForm.phone,
                    code: this.ruleForm.code
                })
                .then(() => {
                    this.$http
                        .post('/user/changePhone', { phone: this.ruleForm.phone })
                        .then(() => {
                            this.$message.warning('更改成功');
                            localStorage.removeItem('webToken');
                            this.$store.commit('updateUserInfo', null);
                            this.$router.push('/');
                        })
                        .catch(e => {
                            this.$message.error(e.error);
                        });
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        },
        Jump() {
            this.$emit('Jump');
        }
    }
};
</script>
<style lang="less" scoped>
/deep/.el-step:nth-child(1) {
    .el-step__icon {
        background: #ff8700;
        color: #ffffff;
        border: 0;
    }
}
/deep/.el-step:nth-child(2) {
    .el-step__icon {
        background: #01a041 !important;
        color: #ffffff;
        border: 0;
    }
}
/deep/.el-step:nth-child(3) {
    .el-step__icon {
        background: #c8c9cc;
        color: #ffffff;
        border: 0;
    }
}
/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
}
/deep/ .el-form-item__label {
    font-size: 13px;
}
/deep/ .el-step__title.is-process {
    font-weight: 400;
    color: #939599;
}
/deep/ .el-step__title.is-wait {
    color: #939599;
}
.container {
    .btn {
        text-align: center;
    }
    /deep/ .el-button {
        width: 110px;
        height: 36px;
        border-radius: 4px;
        margin-right: 10px;
        line-height: 1px;
    }
    /deep/ .el-step__title {
        font-size: 13px;
    }
    /deep/ .el-form-item__label {
        color: #313233;
    }
    /deep/ .el-form {
        margin: 61px 0 80px 0;
    }
}
</style>
