import { render, staticRenderFns } from "./SecurityPhone.vue?vue&type=template&id=45983362&scoped=true"
import script from "./SecurityPhone.vue?vue&type=script&lang=js"
export * from "./SecurityPhone.vue?vue&type=script&lang=js"
import style0 from "./SecurityPhone.vue?vue&type=style&index=0&id=45983362&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45983362",
  null
  
)

export default component.exports