<template>
    <div class="container">
        <div v-if="phoneFlag">
            <div class="title">账号安全</div>
            <div class="box">
                <div class="val">
                    <div class="text1">当前手机号</div>
                    <div class="text2">已绑定手机号 {{ Phone }}</div>
                    <div class="text3" @click="phoneFn">变更手机号</div>
                </div>
                <div class="val">
                    <div class="text1">登录密码</div>
                    <div class="text2">已设置</div>
                    <div class="text3" @click="modify">修改密码</div>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="modifyFlag">
                <div class="title">变更手机号</div>
                <div v-if="flag">
                    <el-steps :space="180" :active="1" align-center>
                        <el-step title="验证当前手机号"></el-step>
                        <el-step title="绑定新手机号"></el-step>
                        <el-step title="完成"></el-step>
                    </el-steps>
                    <el-form
                        :label-position="labelPosition"
                        ref="form2"
                        label-width="340px"
                        :model="ruleForm"
                        :rules="rules"
                    >
                        <el-form-item label="当前手机号" prop="phone">
                            <el-input style="width: 300px" :disabled="true" v-model="ruleForm.phone">{{
                                userInfo.phone
                            }}</el-input>
                        </el-form-item>
                        <el-form-item label="验证码" prop="code">
                            <el-input style="width: 300px" v-model="ruleForm.code" placeholder="请输入4位验证码">
                                <span
                                    @click="send"
                                    style="cursor: pointer; font-size: 13px"
                                    slot="suffix"
                                    >{{ time === 0 ? '发送验证码' : `重新发送${time}s` }}</span
                                >
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <div class="btn">
                        <el-button type="primary" @click="codeSend">确认</el-button>
                        <el-button type="info" @click="Jump">返回</el-button>
                    </div>
                </div>
                <div v-else>
                    <security-phone @Jump="Jump"></security-phone>
                </div>
            </div>
            <div v-else>
                <div class="title">修改密码</div>
                <el-form
                    :label-position="labelPosition"
                    label-width="343px"
                    :model="ruleForm"
                    ref="form2"
                    :rules="rules"
                >
                    <el-form-item label="原密码" prop="password">
                        <el-input
                            style="width: 300px"
                            type="password"
                            v-model="ruleForm.password"
                            placeholder="请输入"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="pass">
                        <el-input
                            style="width: 300px"
                            type="password"
                            v-model="ruleForm.pass"
                            placeholder="请输入"
                            autocomplete="off"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="确认新密码" prop="checkPass">
                        <el-input
                            style="width: 300px"
                            type="password"
                            v-model="ruleForm.checkPass"
                            placeholder="请输入"
                            autocomplete="off"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div class="btn">
                    <el-button type="primary" @click="submitForm">确认</el-button>
                    <el-button type="info" @click="phoneFlag = true">返回</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SecurityPhone from '../../components/SecurityPhone.vue';
import { mapState } from 'vuex';
export default {
    components: { SecurityPhone },
    data() {
        return {
            phoneFlag: true,
            modifyFlag: true,
            flag: true,
            labelPosition: 'right',
            ruleForm: {
                phone: '',
                code: '',
                pass: '',
                password: '',
                checkPass: '',
                type: ''
            },
            time: 0,
            rules: {
                checkPass: [
                    {
                        validator: (rule, value, callback) => {
                            if (value !== this.ruleForm.pass) {
                                callback(new Error('两次密码输入不一致'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    },
                    { required: true, message: '请再次输入密码', trigger: 'blur' }
                ]
            }
        };
    },
    computed: {
        ...mapState(['userInfo']),
        Phone() {
            return this.userInfo.phone.slice(0, 3) + ' **** ' + this.userInfo.phone.slice(7, 11);
        }
    },
    created() {
        this.ruleForm.phone = this.userInfo.phone;
    },
    methods: {
        send() {
            this.sendCode(this.ruleForm.phone);
        },
        codeSend() {
            this.$http
                .get('/sms/verify', {
                    phone: this.ruleForm.phone,
                    code: this.ruleForm.code
                })
                .then(() => {
                    this.ruleForm.code = '';
                    this.ruleForm.phone = '';
                    this.flag = false;
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        },
        submitForm() {
            this.$refs.form2.validate(valid => {
                if (valid) {
                    this.$http
                        .post('/user/changePw', {
                            newPassword: this.ruleForm.pass,
                            password: this.ruleForm.password
                        })
                        .then(() => {
                            this.$message.warning('修改成功,请重新登录');
                            localStorage.removeItem('webToken');
                            this.$store.commit('updateUserInfo', null);
                        })
                        .then(() => {
                            this.$router.push('/Login');
                        })
                        .catch(e => {
                            this.$message.error(e.error);
                        });
                }
            });
        },
        phoneFn() {
            this.phoneFlag = false;
            this.modifyFlag = true;
            this.flag = true;
        },
        modify() {
            this.phoneFlag = false;
            this.modifyFlag = false;
        },
        Jump() {
            this.phoneFlag = true;
            this.ruleForm.phone = this.userInfo.phone;
        }
    }
};
</script>

<style lang="less" scoped>
/deep/.el-step:nth-child(1) {
    .el-step__icon {
        background: #01a041;
        color: #ffffff;
        border: 0;
    }
}
/deep/.el-step:nth-child(2) {
    .el-step__icon {
        background: #c8c9cc;
        color: #ffffff;
        border: 0;
    }
}
/deep/ .el-step__title.is-finish {
    color: #01a041;
}
/deep/.el-step:nth-child(3) {
    .el-step__icon {
        background: #c8c9cc;
        color: #ffffff;
        border: 0;
    }
}
/deep/ .el-input__suffix-inner {
    color: #01a041 !important;
}
/deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
}
/deep/ .el-form-item__label {
    font-size: 13px;
}
/deep/ .el-step__title.is-process {
    font-weight: 400;
    color: #939599;
}
/deep/ .el-step__title.is-wait {
    color: #939599;
}
.container {
    .btn {
        text-align: center;
    }
    /deep/ .el-button {
        width: 110px;
        height: 36px;
        border-radius: 4px;
        margin-right: 10px;
        line-height: 1px;
    }
    /deep/ .el-step__title {
        font-size: 13px;
    }
    /deep/ .el-form-item__label {
        color: #313233;
    }
    /deep/ .el-form {
        margin: 61px 0 80px 0;
    }
    /deep/ .el-steps {
        margin: 30px 0 0 215px;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 17px;
        line-height: 26px;
        border-bottom: 1px solid @bg;
        margin: 17px 20px 0;
    }
    /deep/ .el-button--primary {
        background: #01a041;
        border-radius: 4px;
        border-color: #01a041;
        box-sizing: border-box;
    }
    .box {
        margin-top: 47px;
        margin-left: 26%;
        .val {
            .flex();
            margin-bottom: 34px;
            .text1 {
                width: 70px;
                font-size: 13px;
                text-align: right;
                font-weight: 400;
                color: #313233;
                line-height: 22px;
            }
            .text2 {
                font-size: 13px;
                font-weight: 400;
                color: #939599;
                line-height: 22px;
                width: 170px;
                margin: 0 30px 0 34px;
            }
            .text3 {
                font-size: 13px;
                font-weight: 400;
                color: #01a041;
                line-height: 18px;
                cursor: pointer;
            }
        }
    }
}
/deep/ .is-finish {
    border-color: #01a041 !important;
    color: #01a041 !important;
}
</style>
